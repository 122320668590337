import { OrganizationInviteStatus } from '@plug/graphql/types';
import gql from 'graphql-tag';

export const GET_USER_ACTIVE_STATUS = gql`
  query GetUserActiveStatus($input: getUserActiveStatusInput!) {
    getUserActiveStatus(input: $input) {
      active
      inviteStatus
    }
  }
`;

export type GetUserActiveStatusVariables = {
  input: {
    userId: string;
    organizationId: string;
  };
};

export type GetUserActiveStatusResponse = {
  getUserActiveStatus: {
    active?: boolean;
    inviteStatus?: OrganizationInviteStatus;
  };
};
